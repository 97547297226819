import React, {Component} from 'react'
import API from '../API'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'

import TitlePage from '../Components/TitlePage'

export default class HistoricoPage extends Component{
    constructor(props){
        super(props)

        const id_paciente = this.props.paciente

        this.state = {
            success : false
        }

        API.historico.get(id_paciente).then(paciente => {
            this.setState({
                paciente,
                success: true,
                id_paciente
            })
        }).catch(err => {
            this.setState({
                success : false
            })
        })
    }

    render(){
        const {paciente} = this.state
        if(!this.state.success){
            return (
                <div className="container-fluid">
                    <TitlePage text="Hitórico do paciente"/>
                    <div className="row">
                        <div className="col">
                            <h3>Falha ao carregar dados do paciente.</h3>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="container-fluid">
                <TitlePage text="Hitórico do paciente"/>
                <div className="row">
                    <div className="col">
                        <h4>Paciente</h4><hr/>
                        <div className="row">
                            <div className="col">
                                <p><strong>Nome: </strong>{paciente.nome}</p>
                                <p><strong>Data de Nascimento: </strong>{paciente.data_nascimento}</p>
                            </div>
                            <div className="col">
                                <p><strong>Nome do Pai: </strong>{paciente.pai}</p>
                                <p><strong>Nome da Mãe: </strong>{paciente.mae}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <h4>Consultas</h4><hr/>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Psicólogo</th>
                                    <th>Data</th>
                                    <th>Observações</th>
                                    <th>Presença</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    paciente.consultas.map(consulta => {
                                        return (
                                            <tr key={consulta.id}>
                                                <td>{consulta.id}</td>
                                                <td>{consulta.psicologo ? consulta.psicologo.nome : null}</td>
                                                <td>{consulta.dataHora}</td>
                                                <td>{consulta.observacoes}</td>
                                                <td>
                                                    {consulta.presenca ? <FontAwesomeIcon icon={faCheck} className="text-success" /> : <FontAwesomeIcon icon={faTimes} className="text-danger" />}
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}