const token = localStorage.getItem("token")
const API = {
    fila: {
        get: () => {
            let headers = new Headers()
            headers.append("Content-type", "application/json")
            headers.append("Accept", "application/json")

            return new Promise((resolve, reject) => {
                fetch(`${URL.fila}?token=${token}`)
                    .then(res => {
                        if(res.ok){
                            resolve(res.json())
                        }else{
                            reject(res.text())
                        }
                    })
            })
        }
    },
    alerta: {
        get: () => {
            let headers = new Headers()
            headers.append("Content-type", "application/json")
            headers.append("Accept", "application/json")

            return new Promise((resolve, reject) => {
                fetch(`${URL.alerta}?token=${token}`)
                    .then(res => {
                        if(res.ok){
                            resolve(res.json())
                        }else{
                            reject(res.text())
                        }
                    })
            })
        }
    },
    estatistica: {
        get: () => {
            let headers = new Headers()
            headers.append("Content-type", "application/json")
            headers.append("Accept", "application/json")

            return new Promise((resolve, reject) => {
                fetch(`${URL.estatistica}?token=${token}`)
                    .then(res => {
                        if(res.ok){
                            resolve(res.json())
                        }else{
                            reject(res.text())
                        }
                    })
            })
        }
    },
    removerAcompanhamento: (paciente_id) => {
        let headers = new Headers()
        headers.append("Content-type", "application/json")
        headers.append("Accept", "application/json")
        return new Promise((resolve, reject) => {
            fetch(`${URL.paciente}/${paciente_id}/acompanhamentoRetirado?token=${token}`, {
                method: 'PUT',
                mode: 'cors',
                headers
            }).then(res => {
                if(res.ok){
                    resolve(res.json())
                }else{
                    reject(res.text())
                }
            })
        })
    },
    encaminhamento: {
        save: (encaminhamento) => {
            let headers = new Headers()
            headers.append("Content-type", "application/json")
            headers.append("Accept", "application/json")
            return new Promise((resolve, reject) => {
                fetch(`${URL.encaminhamento}?token=${token}`, {
                    method: 'POST',
                    mode: 'cors',
                    body: JSON.stringify(encaminhamento),
                    headers
                }).then(res => {
                    if(res.ok){
                        resolve(res.json())
                    }else{
                        reject(res.text())
                    }
                })
            })
        },
        update: (encaminhamento) => {
            let headers = new Headers()
            headers.append("Content-type", "application/json")
            headers.append("Accept", "application/json")
            return new Promise((resolve, reject) => {
                fetch(`${URL.encaminhamento}/${encaminhamento.id}?token=${token}`, {
                    method: 'PUT',
                    mode: 'cors',
                    body: JSON.stringify(encaminhamento),
                    headers
                }).then(res => {
                    if(res.ok){
                        resolve(res.json())
                    }else{
                        reject(res.text())
                    }
                })
            })
        },
        get: (id = null) => {
            let headers = new Headers()
            headers.append("Content-type", "application/json")
            headers.append("Accept", "application/json")
            return new Promise((resolve, reject) => {
                fetch(`${URL.encaminhamento}${id != null ? '/'+id : ''}?token=${token}`)
                    .then(res => {
                        if(res.ok){
                            resolve(res.json())
                        }else{
                            reject(res.text())
                        }
                    })
            })
        },
        getByPatient: (id) => {
            let headers = new Headers()
            headers.append("Content-type", "application/json")
            headers.append("Accept", "application/json")
            return new Promise((resolve, reject) => {
                fetch(`${URL.encaminhamento}?paciente_id=${id}&token=${token}`)
                    .then(res => {
                        if(res.ok){
                            resolve(res.json())
                        }else{
                            reject(res.text())
                        }
                    })
            })
        },
        delete: (id) => {
            let headers = new Headers()
            headers.append("Content-type", "application/json")
            headers.append("Accept", "application/json")
            return new Promise((resolve, reject) => {
                fetch(`${URL.encaminhamento}/${id}?token=${token}`, {
                    method: 'DELETE',
                    mode: 'cors',
                    headers
                }).then(res => {
                    if(res.ok){
                        resolve('ok')
                    }else{
                        reject(res.text())
                    }
                })
            })
        }
    },
    psicologo: {
        save: (psicologo) => {
            let headers = new Headers()
            headers.append("Content-type", "application/json")
            headers.append("Accept", "application/json")
            return new Promise((resolve, reject) => {
                fetch(`${URL.psicologo}?token=${token}`, {
                    method: 'POST',
                    mode: 'cors',
                    body: JSON.stringify(psicologo),
                    headers
                }).then(res => {
                    if(res.ok){
                        resolve(res.json())
                    }else{
                        reject(res.text())
                    }
                })
            })
        },
        get: (id = null) => {
            let headers = new Headers()
            headers.append("Content-type", "application/json")
            headers.append("Accept", "application/json")
            return new Promise((resolve, reject) => {
                fetch(`${URL.psicologo}${id != null ? '/'+id : ''}?token=${token}`)
                    .then(res => {
                        if(res.ok){
                            resolve(res.json())
                        }else{
                            reject(res.text())
                        }
                    })
            })
        },
        getByUser: (id) => {
            let headers = new Headers()
            headers.append("Content-type", "application/json")
            headers.append("Accept", "application/json")
            return new Promise((resolve, reject) => {
                fetch(`${URL.psicologo}?token=${token}&id=${id}`)
                    .then(res => {
                        if(res.ok){
                            resolve(res.json())
                        }else{
                            reject(res.text())
                        }
                    })
            })
        },
        delete: (id) => {
            let headers = new Headers()
            headers.append("Content-type", "application/json")
            headers.append("Accept", "application/json")
            return new Promise((resolve, reject) => {
                fetch(`${URL.psicologo}/${id}?token=${token}`, {
                    method: 'DELETE',
                    mode: 'cors',
                    headers
                }).then(res => {
                    if(res.ok){
                        resolve('ok')
                    }else{
                        reject(res.text())
                    }
                })
            })
        }
    },
    paciente: {
        getExcluidos: () => {
            let headers = new Headers()
            headers.append("Content-type", "application/json")
            headers.append("Accept", "application/json")
            return new Promise((resolve, reject) => {
            fetch(`${URL.paciente}/excluido?token=${token}`)
                .then(res => {
                    if(res.ok){
                        resolve(res.json())
                    }else{
                        reject(res.text())
                    }
                })
            })
        },
        save: (paciente) => {
            let headers = new Headers()
            headers.append("Content-type", "application/json")
            headers.append("Accept", "application/json")
            return new Promise((resolve, reject) => {
                fetch(`${URL.paciente}?token=${token}`, {
                    method: 'POST',
                    mode: 'cors',
                    body: JSON.stringify(paciente),
                    headers
                }).then(res => {
                    if(res.ok){
                        resolve(res.json())
                    }else{
                        reject(res.text())
                    }
                })
            })
        },
        get: (id = null) => {
            let headers = new Headers()
            headers.append("Content-type", "application/json")
            headers.append("Accept", "application/json")
            return new Promise((resolve, reject) => {
                fetch(`${URL.paciente}${id != null ? '/'+id : ''}?token=${token}`)
                    .then(res => {
                        if(res.ok){
                            resolve(res.json())
                        }else{
                            reject(res.text())
                        }
                    })
            })
        },
        getByNome: (nome) => {
            let headers = new Headers()
            headers.append("Content-type", "application/json")
            headers.append("Accept", "application/json")
            return new Promise((resolve, reject) => {
                fetch(`${URL.paciente}?token=${token}&nome=${nome}`)
                    .then(res => {
                        if(res.ok){
                            resolve(res.json())
                        }else{
                            reject(res.text())
                        }
                    })
            })
        },
        delete: (id) => {
            let headers = new Headers()
            headers.append("Content-type", "application/json")
            headers.append("Accept", "application/json")
            return new Promise((resolve, reject) => {
                fetch(`${URL.paciente}/${id}?token=${token}`, {
                    method: 'DELETE',
                    mode: 'cors',
                    headers
                }).then(res => {
                    if(res.ok){
                        resolve('ok')
                    }else{
                        reject(res.text())
                    }
                })
            })
        },
        restore: (id) => {
            let headers = new Headers()
            headers.append("Content-type", "application/json")
            headers.append("Accept", "application/json")
            return new Promise((resolve, reject) => {
                fetch(`${URL.paciente}/${id}/restaurar?token=${token}`, {
                    method: 'POST',
                    mode: 'cors',
                    headers
                }).then(res => {
                    if(res.ok){
                        resolve('ok')
                    }else{
                        reject(res.text())
                    }
                })
            })
        }
    },
    usuario: {
        save: (usuario) => {
            let headers = new Headers()
            headers.append("Content-type", "application/json")
            headers.append("Accept", "application/json")
            return new Promise((resolve, reject) => {
                fetch(`${URL.usuario}?token=${token}`, {
                    method: 'POST',
                    mode: 'cors',
                    body: JSON.stringify(usuario),
                    headers
                }).then(res => {
                    if(res.ok){
                        resolve(res.json())
                    }else{
                        reject(res.text())
                    }
                })
            })
        },
        get: () => {
            let headers = new Headers()
            headers.append("Content-type", "application/json")
            headers.append("Accept", "application/json")
            return new Promise((resolve, reject) => {
                fetch(`${URL.usuario}?token=${token}`)
                    .then(res => {
                        if(res.ok){
                            resolve(res.json())
                        }else{
                            reject(res.text())
                        }
                    })
            })
        },
        delete: (id) => {
            let headers = new Headers()
            headers.append("Content-type", "application/json")
            headers.append("Accept", "application/json")
            return new Promise((resolve, reject) => {
                fetch(`${URL.usuario}/${id}?token=${token}`, {
                    method: 'DELETE',
                    mode: 'cors',
                    headers
                }).then(res => {
                    if(res.ok){
                        resolve('ok')
                    }else{
                        reject(res)
                    }
                })
            })
        }
    },
    consulta: {
        save: (consulta) => {
            let headers = new Headers()
            headers.append("Content-type", "application/json")
            headers.append("Accept", "application/json")
            return new Promise((resolve, reject) => {
                fetch(`${URL.consulta}?token=${token}`, {
                    method: 'POST',
                    mode: 'cors',
                    body: JSON.stringify(consulta),
                    headers
                }).then(res => {
                    console.log(res)
                    if(res.ok){
                        resolve(res.json())
                    }else{
                        reject(res.text())
                    }
                })
            })
        },
        update: (consulta) => {
            let headers = new Headers()
            headers.append("Content-type", "application/json")
            headers.append("Accept", "application/json")
            return new Promise((resolve, reject) => {
                fetch(`${URL.consulta}/${consulta.id}?token=${token}`, {
                    method: 'PUT',
                    mode: 'cors',
                    body: JSON.stringify(consulta),
                    headers
                }).then(res => {
                    console.log(res)
                    if(res.ok){
                        resolve(res.json())
                    }else{
                        reject(res.text())
                    }
                })
            })
        },
        get: () => {
            let headers = new Headers()
            headers.append("Content-type", "application/json")
            headers.append("Accept", "application/json")
            return new Promise((resolve, reject) => {
                fetch(`${URL.consulta}?token=${token}`)
                    .then(res => {
                        if(res.ok){
                            resolve(res.json())
                        }else{
                            reject(res.text())
                        }
                    })
            })
        },
        getByPsicologo: (psicologo_id) => {
            let headers = new Headers()
            headers.append("Content-type", "application/json")
            headers.append("Accept", "application/json")
            return new Promise((resolve, reject) => {
                fetch(`${URL.consulta}?token=${token}&psicologo_id=${psicologo_id}`)
                    .then(res => {
                        if(res.ok){
                            resolve(res.json())
                        }else{
                            reject(res.text())
                        }
                    })
            })
        },
        getById: (id) => {
            let headers = new Headers()
            headers.append("Content-type", "application/json")
            headers.append("Accept", "application/json")
            return new Promise((resolve, reject) => {
                fetch(`${URL.consulta}/${id}?token=${token}`)
                    .then(res => {
                        if(res.ok){
                            resolve(res.json())
                        }else{
                            reject(res.text())
                        }
                    })
            })
        },
        delete: (id) => {
            let headers = new Headers()
            headers.append("Content-type", "application/json")
            headers.append("Accept", "application/json")
            return new Promise((resolve, reject) => {
                fetch(`${URL.consulta}/${id}?token=${token}`, {
                    method: 'DELETE',
                    mode: 'cors',
                    headers
                }).then(res => {
                    if(res.ok){
                        resolve('ok')
                    }else{
                        reject(res)
                    }
                })
            })
        }
    },
    login: (dados) => {
        let headers = new Headers()
        headers.append("Content-type", "application/json")
        headers.append("Accept", "application/json")
        return new Promise((resolve, reject) => {
            fetch(`${URL.login}`, {
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify(dados),
                headers
            }).then(res => {
                if(res.ok){
                    resolve(res.json())
                }else{
                    reject(res)
                }
            })
        })
    },
    historico: {
        get: id_paciente => {
            let headers = new Headers()
            headers.append("Content-type", "application/json")
            headers.append("Accept", "application/json")
            return new Promise((resolve, reject) => {
                fetch(`${URL.historico}/${id_paciente}`, {
                    method: 'GET',
                    mode: 'cors',
                    headers
                }).then(res => {
                    if(res.ok){
                        resolve(res.json())
                    }else{
                        reject(res)
                    }
                })
            })
        }
    }
}

const baseURL = 'http://psicologiaapi.progm.net.br/'

const URL = {
    usuario: `${baseURL}user`,
    login: `${baseURL}login`,
    encaminhamento: `${baseURL}encaminhamento`,
    paciente: `${baseURL}paciente`,
    consulta: `${baseURL}consulta`,
    psicologo: `${baseURL}psicologo`,
    fila: `${baseURL}fila`,
    alerta: `${baseURL}alerta`,
    estatistica: `${baseURL}estatisticas`,
    historico: `${baseURL}historico`
}

export default API