import React from 'react'
import { useParams } from 'react-router-dom'

import HistoricoPage from '../Pages/HistoricoPage'

const HistoricoHook = () => {
    const {id} = useParams()
    return (
        <HistoricoPage paciente={id}/>
    )
}

export default HistoricoHook