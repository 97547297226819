import React, { Component } from 'react'
import TitlePage from '../Components/TitlePage'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import API from '../API'

export default class PsicologoPage extends Component{
    state = {
        psicologos: []
    }

    constructor(props){
        super(props)
        API.psicologo.get().then(data => this.setState({psicologos: data.data}))
    }

    delete = (id) => {
        API.psicologo.delete(id).then(data => this.setState({
            psicologos: this.state.psicologos.filter(psicologo => psicologo.id !== id)
        }))
    }

    render(){
        return (
            <div className="container-fluid">
                <TitlePage text="Gerenciar Psicólogos cadastrados"/>
                <div className="row">
                    <div className="col">
                        <button className="btn btn-default">
                            <Link to="psicologo/novo">Cadastrar novo</Link>
                        </button>

                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Matrícula</th>
                                    <th>Nome</th>
                                    <th>Órgão</th>
                                    <th>Registro Profissional</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.psicologos.map(usuario => {
                                        return (
                                            <tr key={usuario.id}>
                                                <td>{usuario.matricula}</td>
                                                <td>{usuario.nome}</td>
                                                <td>{usuario.orgao}</td>
                                                <td>{usuario.registro_profissional}</td>
                                                <td>
                                                    <button className="btn btn-danger btn-sm" onClick={() => this.delete(usuario.id)}>
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}