import React, { Component } from 'react'
import TitlePage from '../Components/TitlePage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import API from '../API'
import swal from 'sweetalert'

export default class NovoEncaminhamentoPage extends Component{
    state = {
        containerBuscarCrianca: false,
        pacientes: [],
        nomePaciente: "",
        encaminhamento: {
            id_paciente: "",
            id_usuario: 2,
            descricao: ""
        }
    }

    toggleBuscarCrianca = () => {
        this.setState({containerBuscarCrianca: !this.state.containerBuscarCrianca})
    }

    getPatients = () => {
        API.paciente.getByNome(this.state.nomePaciente).then(data => this.setState({pacientes: data.data}))
    }

    save = (evt) => {
        evt.preventDefault()

        if(this.state.encaminhamento.id_paciente === ""){
            swal("Escolha um paciente.")
            return
        }

        API.encaminhamento.save(this.state.encaminhamento)
            .then(data => {
                swal("Dados salvos com sucesso.")
            })
            .catch(err => {
                swal("Falha ao salvar dados.")
            })
    }

    render(){
        return (
            <div className="container-fluid">

                <TitlePage text="Cadastrar novo encaminhamento"/>
                <div className="row">
                    <div className="col">
                        <form onSubmit={this.save}>
                            <label>Código de cadastrado do paciente</label>
                            <div className="row form-group">
                                <div className="col-4">
                                    <input required type="text" className="form-control" value={this.state.encaminhamento.id_paciente} disabled/>
                                </div>
                                <div className="col">
                                    <button className="btn btn-form" type="button" onClick={() => this.toggleBuscarCrianca()}>
                                        <FontAwesomeIcon icon={faSearch} />
                                    </button>
                                </div>
                            </div>
                            {
                                this.state.containerBuscarCrianca ? 
                                    <div className="row">
                                        <div className="col">
                                            <div>
                                                <label>Nome do paciente</label>
                                                <input type="text" className="form-control col-4" onChange={(evt) => {
                                                    this.setState({
                                                        nomePaciente: evt.target.value
                                                    })
                                                }}/><button type="button" className="btn btn-default" onClick={this.getPatients}>Buscar</button>
                                            </div>
                                            <table className="table">
                                                <tbody>
                                                    {
                                                        this.state.pacientes.map(pac => {
                                                            return (
                                                                <tr key={pac.id}>
                                                                    <td><button type="button" onClick={() => {
                                                                        this.setState({
                                                                            encaminhamento: {
                                                                                ...this.state.encaminhamento,
                                                                                id_paciente: pac.id
                                                                            },
                                                                            containerBuscarCrianca: false
                                                                        })
                                                                    }} className="btn btn-success">Selecionar</button></td>
                                                                    <td>{pac.nome}</td>
                                                                    <td>{pac.mae}</td>
                                                                    <td>{pac.data_nascimento}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                : null
                            }
                            <div className="row form-group">
                                <div className="col">
                                    <label>Queixa, História, descrição</label>
                                    <textarea required className="form-control" rows="5" onChange={(evt) => {
                                        this.setState({
                                            encaminhamento: {
                                                ...this.state.encaminhamento,
                                                descricao: evt.target.value
                                            }
                                        })
                                    }}
                                    value={this.state.encaminhamento.descricao}></textarea>
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col">
                                    <label>Hipótese diagnosticada</label>
                                    <textarea required className="form-control" rows="5" onChange={(evt) => {
                                        this.setState({
                                            encaminhamento: {
                                                ...this.state.encaminhamento,
                                                hipotese_diagnosticada: evt.target.value
                                            }
                                        })
                                    }}
                                    value={this.state.encaminhamento.hipotese_diagnosticada}></textarea>
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col">
                                    <label>Motivo do encaminhamento</label>
                                    <textarea required className="form-control" rows="5" onChange={(evt) => {
                                        this.setState({
                                            encaminhamento: {
                                                ...this.state.encaminhamento,
                                                motivo_encaminhamento: evt.target.value
                                            }
                                        })
                                    }}
                                    value={this.state.encaminhamento.motivo_encaminhamento}></textarea>
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col text-center">
                                    <button type="submit" className="btn btn-success">
                                        Enviar
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}