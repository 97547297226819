import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import TitlePage from '../Components/TitlePage'
import API from '../API'
import swal from 'sweetalert'


const VerPacientePage = () => {
    const {id} = useParams()
    const [paciente, setPaciente] = useState({})

    useEffect(() => {
        API.paciente.get(id)
            .then(p => setPaciente(p))
            .catch(err => swal("Não foi possível carregar os dados do paciente. Erro no servidor de banco de dados."))
    })
    return (
        <div className="container-fluid">
            <TitlePage text="Ver dados do paciente cadastrado"/>
            <div className="row">
                <div className="col">
                    <p>
                        <strong>Nome:</strong> {paciente.nome}
                    </p>
                    <p>
                        <strong>Data de nascimento:</strong> {paciente.data_nascimento}
                    </p>
                    <p>
                        <strong>Nome da mãe :</strong> {paciente.mae}
                    </p>
                    <p>
                        <strong>Nome do Pai:</strong> {paciente.pai}
                    </p>
                    <p>
                        <strong>Cartão SUS:</strong> {paciente.cartao_sus}
                    </p>
                    <p>
                        <strong>UBS de origem:</strong> {paciente.ubs_origem}
                    </p>
                    <p>
                        <strong>Escola:</strong> {paciente.escola}
                    </p>
                    <p>
                        <strong>Ano:</strong> {paciente.ano}
                    </p>
                    <p>
                        <strong>Período:</strong> {paciente.periodo}
                    </p>
                    <p>
                        <strong>Família acompanhada pelo CRAS:</strong> {+paciente.acompanhamento_cras === 1 ? "Sim" : "Não"}
                    </p>
                    <p>
                        <strong>Motivo de não acompanhamento:</strong> {paciente.motivo_nao_acompanhamento}
                    </p>
                    <p>
                        <strong>CRAS de referência:</strong> {paciente.cras_referencia}
                    </p>
                    <p>
                        <strong>Cidade:</strong> {paciente.cidade}
                    </p>
                    <p>
                        <strong>Endereço:</strong> {paciente.endereco}
                    </p>
                    <p>
                        <strong>Telefone:</strong> {paciente.telefone}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default VerPacientePage