import React from 'react'
import { useParams } from 'react-router-dom'

import VerConsultaPage from '../Pages/VerConsultaPage'

const ConsultaHook = () => {
    const {id} = useParams()
    return (
        <VerConsultaPage consulta={id}/>
    )
}

export default ConsultaHook