import React, { useEffect, useState } from 'react'
import TitlePage from '../Components/TitlePage'
import API from '../API'
import { useParams } from 'react-router-dom'
import swal from 'sweetalert'



export default function NovaConsultaPage(){
    const json = localStorage.getItem("token")
    let psicologo = {}
    if(json){
        const usuario = JSON.parse(atob(json.split(".")[1]))
        psicologo = usuario.psicologo
    }

    const date = new Date()
    const [paciente, setPaciente] = useState({})
    const [encaminhamentos, setEncaminhamentos] = useState([])
    const [encaminhamentoSelecionado, setEncaminhamentoSelecionado] = useState(0)
    const [dataAgendada, setDataAgendada] = useState(`${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, `0`)}-${date.getDate()}`)
    const [camposComErro, setCamposComErro] = useState([])

    const {id} = useParams()
    useEffect(() => {
        API.paciente.get(id)
            .then(p => setPaciente(p))
            .catch(err => swal("Erro ao carregar paciente. Erro no servidor de banco de dados."))
    }, [])

    useEffect(() => {
        API.encaminhamento.getByPatient(id)
            .then(e => setEncaminhamentos(e))
            .catch(err => swal("Erro ao carregar encmainhamentos. Erro no servidor de banco de dados."))
    }, [])

    const saveConsult = () => {
        const consult = {
            dataHora:dataAgendada,
            id_encaminhamento: encaminhamentoSelecionado,
            presenca: false,
            id_psicologo: psicologo.id
        }

        API.consulta.save(consult)
            .then(res => {
                swal("Consulta agendada com sucesso. Consulte agenda.")
            }).catch(err => {

                err.then(msg => {
                    msg = JSON.parse(msg)
                    setCamposComErro(Object.keys(msg))
                })
                
                swal("Falha ao agendar consulta. Confira os dados e tente novamente.")
            })
    }

    return (
        <div className="container-fluid">
            <TitlePage text="Agendar nova consulta"/>
            <div className="row">
                <div className="col">
                    <p>
                        <strong>Nome do Paciente:</strong> {paciente.nome}
                    </p>                    
                </div>
            </div>
            <div className="row">
                <div className="col">
                    {
                        camposComErro.map(c => (<p key={c} className="text-danger">{c} -> Campo obrigatório</p>))
                    }
                </div>
            </div>
            <div className="row">
                <div className="col form-group">
                    <label>Selecione o encaminhamento para agendar uma consulta:</label>
                    <select className="form-control" defaultValue={encaminhamentoSelecionado} onChange={(evt) => setEncaminhamentoSelecionado(evt.target.value)}>
                        <option value="0" disabled>Escolha uma opção</option>
                        {
                            encaminhamentos.map(enc => (<option key={enc.id} value={enc.id}>{enc.id} | {enc.data_criacao} | {enc.motivo_encaminhamento}</option>))
                        }
                    </select>
                </div>
            </div>
            {
                encaminhamentoSelecionado !== 0 ?
                    <>
                        <div className="row">
                            <div className="col form-group">
                                <label>Data da consulta a ser agendada</label>
                                <input type="date" onChange={(evt) => setDataAgendada(evt.target.value)} value={dataAgendada} className="form-control"/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col form-group">
                                <button type="submit" className="btn btn-default" onClick={saveConsult}>Agendar</button>
                            </div>
                        </div>
                    </>
                : null
            }
        </div>
    )
}