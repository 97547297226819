import React, {Component} from 'react'
import SideBar from './SideBar'
import Header from './Header'
import Footer from './Footer'

export default class BasePage extends Component{
    componentDidMount(){
        const token = localStorage.getItem("token")
        if(!token){
            window.location = "/login"
        }
        if(this.props.auth){
            const usuario = JSON.parse(atob(token.split(".")[1]))
            this.props.auth(usuario)
        }
    }
    
    render(){
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-2 container-sidebar">
                        <SideBar/>
                    </div>
                    <div className="col-10 offset-sm-2">
                        <Header/>
                        
                        {this.props.page()}

                        <Footer/>
                        
                    </div>
                </div>
            </div>
        )
    }
}