import React from 'react';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import 'bootstrap/dist/css/bootstrap-reboot.css';
import 'bootstrap/dist/css/bootstrap.css'

import './App.css';

import EncaminhamentosPage from './Pages/EncaminhamentosPage'
import NovoEcaminhamentoPage from './Pages/NovoEcaminhamentoPage'
import PacientesPage from './Pages/PacientesPage';
import PacientesExcluidosPage from './Pages/PacientesExcluidosPage';
import NovoPacientesPage from './Pages/NovoPacientesPage';
import LoginPage from './Pages/LoginPage';
import BasePage from './Components/BasePage';
import FilaPage from './Pages/FilaPage'

import API from './API'
import AgendaPage from './Pages/AgendaPage';
import ConsultaPage from './Pages/ConsultaPage';

import HistoricoPage from './Components/HistoricoHook';
import ConsultaHook from './Components/ConsultaHook';
import EditarEncaminhamentoHook from './Components/EditarEncaminhamentoHook';
import PsicologoPage from './Pages/PsicologoPage';
import NovoPsicologoPage from './Pages/NovoPsicologoPage';
import ImprimirEncaminhamentoPage from './Pages/ImprimirEncaminhamentoPage';
import AgendaPsicologoPage from './Pages/AgendaPsicologoPage';
import VerPacientePage from './Pages/VerPacientePage';
import AlertaPage from './Pages/AlertaPage';
import EstatisticaPage from './Pages/EstatisticaPage';
import NovaConsultaPage from './Pages/NovaConsultaPage';


const auth = () => {
  const token = localStorage.getItem("token")

  if(!token){
    window.location = "/login"
  }
}

const psicologoAuth = (user) => {
  API.psicologo.getByUser(user.id)
    .then(data => {
      
    })
    .catch(err => {
      window.location = "/"
    })
}

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/login" exact>
          <LoginPage />
        </Route>

        <Route path="/" exact>
          <BasePage page={() => null} />
        </Route>

        <Route path="/encaminhamentos" exact>
          <BasePage page={() => <EncaminhamentosPage/>} auth={auth} />
        </Route>

        <Route path="/encaminhamentos/:id/editar" exact>
          <BasePage page={() => <EditarEncaminhamentoHook/>} auth={auth} />
        </Route>

        <Route path="/encaminhamentos/novo" exact>
          <BasePage page={() => <NovoEcaminhamentoPage/>} auth={auth} />
        </Route>

        <Route path="/pacientes" exact>
          <BasePage page={() => <PacientesPage/>} auth={auth} />
        </Route>

        <Route path="/paciente/:id" exact>
          <BasePage page={() => <VerPacientePage/>} auth={auth} />
        </Route>

        <Route path="/pacientes/excluidos" exact>
          <BasePage page={() => <PacientesExcluidosPage/>} auth={auth} />
        </Route>

        <Route path="/pacientes/novo" exact>
          <BasePage page={() => <NovoPacientesPage/>} auth={auth} />
        </Route>

        <Route path="/fila" exact>
          <BasePage page={() => <FilaPage/>} auth={auth} />
        </Route>

        <Route path="/agenda" exact>
          <BasePage page={() => <AgendaPage/>} auth={psicologoAuth} />
        </Route>

        <Route path="/agendaPsicologo" exact>
          <BasePage page={() => <AgendaPsicologoPage/>} auth={psicologoAuth} />
        </Route>

        <Route path="/consulta" exact>
          <BasePage page={() => <ConsultaPage/>} auth={auth} />
        </Route>

        <Route path="/consulta/:id" exact>
          <BasePage page={() => <ConsultaHook/>} auth={auth} />
        </Route>

        <Route path="/historico/:id" exact>
          <BasePage page={() => <HistoricoPage/>} />
        </Route>

        <Route path="/psicologo" exact>
          <BasePage page={() => <PsicologoPage/>} auth={auth}/>
        </Route>

        <Route path="/alerta" exact>
          <BasePage page={() => <AlertaPage/>} auth={auth}/>
        </Route>

        <Route path="/estatistica" exact>
          <BasePage page={() => <EstatisticaPage/>} auth={auth}/>
        </Route>

        <Route path="/psicologo/novo" exact>
          <BasePage page={() => <NovoPsicologoPage/>} auth={auth}/>
        </Route>

        <Route path="/encaminhamentos/:id/imprimir" exact>
          <ImprimirEncaminhamentoPage/>
        </Route>

        <Route path="/paciente/:id/novaConsulta" exact>
          <BasePage page={() => <NovaConsultaPage />} auth={auth}/>
        </Route>

      </Switch>
    </Router>
  );
}

export default App;
