import React, { Component } from 'react'
import TitlePage from '../Components/TitlePage'
import API from '../API'
import swal from 'sweetalert'

const randomPasswd = len => {
    let passwd = ''
    do{
        passwd += Math.random().toString(36).substr(2)
    }while(passwd.length < len)
    passwd = passwd.substr(0, len)

    return passwd
}

export default class NovoPsicologoPage extends Component{
    state = {
        psicologo: {
            senha: randomPasswd(6),
            psicologo: {}
        }
    }

    save = (evt) => {
        evt.preventDefault()

        API.psicologo.save(this.state.psicologo).then(res => {
            swal('Salvo com sucesso.')
        }).catch(err => swal('Erro ao salvar dados.'))
    }

    render(){
        return (
            <div className="container-fluid">
                <TitlePage text="Cadastrar novo psicologo"/>
                <div className="row">
                    <div className="col">

                        <form className="form" onSubmit={this.save}>
                            <div className="row form-group">
                                <div className="col">
                                    <label>Nome</label>
                                    <input type="text" className="form-control" value={this.state.psicologo.nome} onChange={(evt) => {
                                        this.setState({
                                            psicologo: {
                                                ...this.state.psicologo,
                                                nome: evt.target.value
                                            }
                                        })
                                    }}/>
                                </div>
                                <div className="col">
                                    <label>Matrícula</label>
                                    <input type="text" className="form-control"  value={this.state.psicologo.matricula} onChange={(evt) => {
                                        this.setState({
                                            psicologo: {
                                                ...this.state.psicologo,
                                                matricula: evt.target.value
                                            }
                                        })
                                    }} />
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col">
                                    <label>Função</label>
                                    <input type="text" className="form-control"  value={this.state.psicologo.funcao} onChange={(evt) => {
                                        this.setState({
                                            psicologo: {
                                                ...this.state.psicologo,
                                                funcao: evt.target.value
                                            }
                                        })
                                    }}/>
                                </div>
                                <div className="col">
                                    <label>Profissão</label>
                                    <input type="text" className="form-control"  value={this.state.psicologo.profissao} onChange={(evt) => {
                                        this.setState({
                                            psicologo: {
                                                ...this.state.psicologo,
                                                profissao: evt.target.value
                                            }
                                        })
                                    }}/>
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col">
                                    <label>Órgão</label>
                                    <input type="text" className="form-control"  value={this.state.psicologo.orgao} onChange={(evt) => {
                                        this.setState({
                                            psicologo: {
                                                ...this.state.psicologo,
                                                orgao: evt.target.value
                                            }
                                        })
                                    }}/>
                                </div>
                                <div className="col">
                                    <label>Registro Profissonal</label>
                                    <input type="text" className="form-control"  value={this.state.psicologo.registro_profissional} onChange={(evt) => {
                                        this.setState({
                                            psicologo: {
                                                ...this.state.psicologo,
                                                registro_profissional: evt.target.value
                                            }
                                        })
                                    }}/>
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col">
                                    <label>Vagas por semana</label>
                                    <input type="number" className="form-control"  value={this.state.psicologo.vagasSemana} onChange={(evt) => {
                                        this.setState({
                                            psicologo: {
                                                ...this.state.psicologo,
                                                vagasSemana: evt.target.value
                                            }
                                        })
                                    }}/>
                                </div>
                                <div className="col">
                                    <label>Senha</label>
                                    <input type="text" className="form-control"  value={this.state.psicologo.senha} disabled/>
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col text-center">
                                    <button className="btn btn-success" type="submit">
                                        Salvar
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}