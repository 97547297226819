import React, { Component } from 'react'
import TitlePage from '../Components/TitlePage'
import API from '../API'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes, faSearch } from '@fortawesome/free-solid-svg-icons'

export default class ConsultaPage extends Component{

    constructor(props){
        super(props)

        this.state.psicologo = {}

        const json = localStorage.getItem("token")
        if(json){
            const usuario = JSON.parse(atob(json.split(".")[1]))
            this.state.psicologo = usuario.psicologo
        }
    }
    state = {
        consultas: [],
        consulta: {
            paciente: {}
        },
        date: "",
        id_paciente: 0,
        pacientes: []
    }

    toggleBuscarCrianca = () => {
        this.setState({containerBuscarCrianca: !this.state.containerBuscarCrianca})
    }

    getPatients = () => {
        API.paciente.getByNome(this.state.nomePaciente).then(data => this.setState({pacientes: data.data}))
    }

    componentWillMount(){
        API.consulta.get().then(data => this.setState({consultas: data}))
    }

    ver = consulta => {
        console.log(consulta)
        window.location = `/consulta/${consulta.id}`
    }

    render(){
        const consultas = this.state.consultas.filter(consulta => {
            return consulta.paciente.id === this.state.id_paciente
        })

        console.log(consultas)
        
        return (
            <div className="container-fluid">
                
                <TitlePage text="Consultas Realizadas"/>
                <div className="row">
                    <div className="col">
                        <form className="form">
                            <label>Código de cadastrado do paciente <small>(Clique no botão para pesquisar)</small></label>
                            <div className="row form-group">
                                <div className="col-4">
                                    <input type="text" className="form-control" value={this.state.id_paciente} disabled/>
                                </div>
                                <div className="col">
                                    <button className="btn btn-form" type="button" onClick={() => this.toggleBuscarCrianca()}>
                                        <FontAwesomeIcon icon={faSearch} />
                                    </button>
                                </div>
                            </div>
                            {
                                this.state.containerBuscarCrianca ? 
                                    <div className="row">
                                        <div className="col">
                                            <div>
                                                <label>Nome do paciente</label>
                                                <input type="text" className="form-control col-4" onChange={(evt) => {
                                                    this.setState({
                                                        nomePaciente: evt.target.value
                                                    })
                                                }}/><button type="button" className="btn btn-default" onClick={this.getPatients}>Buscar</button>
                                            </div>
                                            <table className="table">
                                                <tbody>
                                                    {
                                                        this.state.pacientes.map(pac => {
                                                            return (
                                                                <tr key={pac.id}>
                                                                    <td><button type="button" onClick={() => {
                                                                        this.setState({
                                                                            id_paciente: pac.id,
                                                                            containerBuscarCrianca: false
                                                                        })
                                                                    }} className="btn btn-success">Selecionar</button></td>
                                                                    <td>{pac.nome}</td>
                                                                    <td>{pac.mae}</td>
                                                                    <td>{pac.data_nascimento}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                : null
                            }
                        </form>
                    </div>
                </div>

                
                {
                    this.state.id_paciente !== 0 ? 
                        <div className="row">
                            <div className="col">

                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Código do paciente</th>
                                            <th>Nome</th>
                                            <th>Data de consulta</th>
                                            <th>Presença</th>
                                            <th>Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            consultas.map(consulta => {
                                                return (
                                                    <tr key={consulta.id}>
                                                        <td>{consulta.paciente.id}</td>
                                                        <td>{consulta.paciente.nome}</td>
                                                        <td>{consulta.dataHora}</td>
                                                        <td>{consulta.presenca ? <FontAwesomeIcon icon={faCheck} className="text-success" /> : <FontAwesomeIcon icon={faTimes} className="text-danger" /> }</td>
                                                        <td>
                                                            <button className="btn btn-primary btn-sm" onClick={() => this.ver(consulta)}>
                                                                Ver
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    : null
                }


            </div>
        )
    }
}