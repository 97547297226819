import React from 'react'
import { useParams } from 'react-router-dom'

import EditarEncaminhamentoPage from '../Pages/EditarEcaminhamentoPage'

const EditarEncaminhamentoHook = () => {
    const {id} = useParams()
    return (
        <EditarEncaminhamentoPage encaminhamento={id}/>
    )
}

export default EditarEncaminhamentoHook