import React, { Component } from 'react'
import TitlePage from '../Components/TitlePage'
import API from '../API'

export default class AlertaPage extends Component{
    state = {
        estatisticas: {
            consultas: []
        },
        consultasMes: {}
    }

    mesesExtenso = {
        "01": "Janeiro",
        "02": "Fevereiro",
        "03": "Março",
        "04": "Abril",
        "05": "Maio",
        "06": "Junho",
        "07": "Julho",
        "08": "Agosto",
        "09": "Setembro",
        "10": "Outubro",
        "11": "Novembro",
        "12": "Dezembro",
    }

    constructor(props){
        super(props)
        API.estatistica.get().then(data => {
            this.setState({estatisticas: data})
        })
    }

    render(){
        return (
            <div className="container-fluid">
                <TitlePage text="Estatísticas"/>
                <div className="row">
                    <div className="col">
                        <table className="table table-striped">
                            <tbody>
                                <tr>
                                    <td>Quantidade de encaminhamentos</td>
                                    <td colSpan="2">{this.state.estatisticas.encaminhamentos}</td>
                                </tr>
                                <tr>
                                    <td>Faltas com justificativas</td>
                                    <td colSpan="2">{this.state.estatisticas.faltasComJustificativas}</td>
                                </tr>
                                <tr>
                                    <td>Pacientes Ativos</td>
                                    <td colSpan="2">{this.state.estatisticas.pacientesAtivos}</td>
                                </tr>
                                <tr>
                                    <td>Pacientes Inativos</td>
                                    <td colSpan="2">{this.state.estatisticas.pacientesInativos}</td>
                                </tr>
                                <tr>
                                    <td>Pacientes Com Acompnhamento pelo CRAS removido</td>
                                    <td colSpan="2">{this.state.estatisticas.pacientesComAcompanhamentoRetirado}</td>
                                </tr>
                                <tr>
                                    <th colSpan="3" className="text-center">Consultas por mês</th>
                                </tr>
                                {
                                    this.state.estatisticas.consultas.map(consulta => {
                                        // console.log(mes)
                                        const dadosData = consulta.mes.split("-")
                                        return (
                                            <tr key={consulta.mes + consulta.id_psicologo}>
                                                <td>{this.mesesExtenso[dadosData[0]] + " de " + dadosData[1]}</td> 
                                                <td>Psicólogo: {consulta.psicologo}</td>
                                                <td>{consulta.quantidade} Consulta (s)</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}