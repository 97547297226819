import React, {Component} from 'react'
import { Link } from 'react-router-dom'

import API from '../API'

export default class LoginPage extends Component{
    constructor(props){
        super(props)
        const token = localStorage.getItem("token")

        if(token){
            window.location = "/"
        }
    }

    state = {
        matricula: '',
        senha: '',
        err: '',
        btnDisabled: false
    }

    login = (evt) => {
        evt.preventDefault()

        const {matricula, senha} = this.state

        const user = {
            matricula,
            senha
        }

        API.login(user)
            .then(data => {
                if(!data.token){
                    throw new Error("Dados incorretos.")
                }

                localStorage.setItem("token", data.token)
                window.location = "/"
            })
            .catch(err => {
                this.setState({err: "Dados incorretos."})
            })
    }

    render(){
        return (
            <div className="container-fluid">
                <div className="row justify-content-sm-center">
                    <div className="col-4 login-box">
                        <div className="row background-logo">
                            <div className="col-12">
                                <img className="img-fluid logo-login" src="img/logo_hor_fundo_claro.png" alt="Sistema de Controle de Violação de Direitos"/>
                            </div>
                        </div>
                        <h5 className="text-bold text-center text-green text-upper">Acessar sistema</h5>
                        <p className="text-danger">{this.state.err}</p>
                        <form className="form-login" onSubmit={this.login}>
                            <div className="form-group">
                                <label className="text-bold">Matrícula</label>
                                <input className="form-control" type="text" value={this.state.matricula} onChange={(matricula) => this.setState({matricula:matricula.target.value})} />
                            </div>
                            <div className="form-group">
                                <label className="text-bold">Senha</label>
                                <input className="form-control" type="password" value={this.state.senha} onChange={(senha) => this.setState({senha:senha.target.value})} />
                            </div>
                            <div className="form-group text-right">
                                <Link to="/esqueci-a-senha" className="link-recover-pass">Esqueci minha senha</Link>
                            </div>
                            <div className="form-group text-center">
                                <button type="submit" disabled={this.state.btnDisabled} className="btn btn-success" onClick={this.fazerLogin}>Entrar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}