import React, { Component } from 'react'
import TitlePage from '../Components/TitlePage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPen, faPrint } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import API from '../API'
import swal from 'sweetalert'

export default class EncaminhamentosPage extends Component{

    constructor(props){
        super(props)

        API.encaminhamento.get().then(data => this.setState({encaminhamentos: data.data}))
    }
    
    state = {
        encaminhamentos: []
    }

    delete = id => {
        API.encaminhamento.delete(id).then(data => {
            const encaminhamentos = this.state.encaminhamentos.filter(enc => enc.id !== id)
            this.setState({encaminhamentos})

            swal("Excluído com sucesso.")
        }).catch(err => {
            console.log(err)
            swal("Falha ao excluir registro.")
        })
    }

    render(){
        return (
            <div className="container-fluid">
                <TitlePage text="Gerenciar Encaminhamentos"/>
                <div className="row">
                    <div className="col">
                        <button className="btn btn-default">
                            <Link to="encaminhamentos/novo">Cadastrar novo</Link>
                        </button>

                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Código</th>
                                    <th>Paciente</th>
                                    <th>Data de envio</th>
                                    <th>Usuário que encaminhou</th>
                                    <th>Psicólogo responsável</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.encaminhamentos.map(enc => {
                                        return (
                                            <tr key={enc.id}>
                                                <td>{enc.id}</td>
                                                <td>{enc.paciente ? enc.paciente.nome : "---"}</td>
                                                <td>{enc.data_criacao}</td>
                                                <td>{enc.usuario.nome + " / " + enc.usuario.funcao}</td>
                                                {
                                                    enc.consultas !== null ?
                                                        <td>{enc.consultas[0].psicologo.nome}</td>
                                                    :
                                                        <td>---</td>
                                                }
                                                <td>
                                                    <Link to="" onClick={(evt) => {
                                                        evt.preventDefault()
                                                        this.delete(enc.id)
                                                    }}>
                                                        <FontAwesomeIcon icon={faTrash}/>
                                                    </Link>&nbsp;
                                                    <Link to={'/encaminhamentos/'+enc.id+'/editar'}>
                                                        <FontAwesomeIcon icon={faPen} />
                                                    </Link>
                                                    <Link to={'/encaminhamentos/'+enc.id+'/imprimir'}>
                                                        <FontAwesomeIcon icon={faPrint}/>
                                                    </Link>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}