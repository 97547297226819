import React, { Component } from 'react'
import TitlePage from '../Components/TitlePage'
import API from '../API'
import swal from 'sweetalert'

export default class NovoPacientesPage extends Component{
    state = {
        paciente: {
            acompanhamento_cras: true
        },
        errors:[]
    }

    save = (evt) => {
        evt.preventDefault()

        API.paciente.save(this.state.paciente).then(res => {
            swal('Salvo com sucesso.')
        }).catch(err => {
            err.then(msg => {
                if(Array.isArray(msg)){
                    this.setState({errors: Object.keys(msg)})
                }else{
                    this.setState({errors: [msg]})
                }
            })

            swal('Erro ao salvar dados.')
        })
    }

    render(){
        return (
            <div className="container-fluid">
                <TitlePage text="Cadastrar novo paciente"/>
                <div className="row">
                    <div className="col">

                        <form className="form" onSubmit={this.save}>
                            <div className="row">
                                <div className="col">
                                    {
                                        this.state.errors.map(err => (<p key={err} className="text-danger">{err}</p>))
                                    }
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col">
                                    <label>Nome</label>
                                    <input required type="text" className="form-control" value={this.state.paciente.nome} onChange={(evt) => {
                                        this.setState({
                                            paciente: {
                                                ...this.state.paciente,
                                                nome: evt.target.value
                                            }
                                        })
                                    }}/>
                                </div>
                                <div className="col">
                                    <label>Data de Nascimento</label>
                                    <input required type="date" className="form-control"  value={this.state.paciente.data_nascimento} onChange={(evt) => {
                                        this.setState({
                                            paciente: {
                                                ...this.state.paciente,
                                                data_nascimento: evt.target.value
                                            }
                                        })
                                    }} />
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col">
                                    <label>Nome da Mãe</label>
                                    <input required type="text" className="form-control"  value={this.state.paciente.mae} onChange={(evt) => {
                                        this.setState({
                                            paciente: {
                                                ...this.state.paciente,
                                                mae: evt.target.value
                                            }
                                        })
                                    }}/>
                                </div>
                                <div className="col">
                                    <label>Nome do Pai</label>
                                    <input required type="text" className="form-control"  value={this.state.paciente.pai} onChange={(evt) => {
                                        this.setState({
                                            paciente: {
                                                ...this.state.paciente,
                                                pai: evt.target.value
                                            }
                                        })
                                    }}/>
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col">
                                    <label>Cartão SUS</label>
                                    <input required type="number" className="form-control"  value={this.state.paciente.cartao_sus} onChange={(evt) => {
                                        this.setState({
                                            paciente: {
                                                ...this.state.paciente,
                                                cartao_sus: evt.target.value
                                            }
                                        })
                                    }}/>
                                </div>
                                <div className="col">
                                    <label>UBS de origem</label>
                                    <input required type="text" className="form-control"  value={this.state.paciente.ubs_origem} onChange={(evt) => {
                                        this.setState({
                                            paciente: {
                                                ...this.state.paciente,
                                                ubs_origem: evt.target.value
                                            }
                                        })
                                    }}/>
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col">
                                    <label>Escola</label>
                                    <input required type="text" className="form-control"  value={this.state.paciente.escola} onChange={(evt) => {
                                        this.setState({
                                            paciente: {
                                                ...this.state.paciente,
                                                escola: evt.target.value
                                            }
                                        })
                                    }}/>
                                </div>
                                <div className="col">
                                    <label>Ano</label>
                                    <input required type="number" className="form-control"  value={this.state.paciente.ano} onChange={(evt) => {
                                        this.setState({
                                            paciente: {
                                                ...this.state.paciente,
                                                ano: evt.target.value
                                            }
                                        })
                                    }}/>
                                </div>
                                <div className="col">
                                    <label>Período</label>
                                    <input required type="text" className="form-control"  value={this.state.paciente.periodo} onChange={(evt) => {
                                        this.setState({
                                            paciente: {
                                                ...this.state.paciente,
                                                periodo: evt.target.value
                                            }
                                        })
                                    }}/>
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col">
                                    <label>Família do paciente é acompanhada pelo CRAS?</label>
                                    <select defaultValue={this.state.paciente.acompanhamento_cras} className="form-control" onChange={(evt) => {
                                        this.setState({
                                            paciente: {
                                                ...this.state.paciente,
                                                acompanhamento_cras: evt.target.value
                                            }
                                        })
                                    }}>
                                        <option value={1}>Sim</option>
                                        <option value={0}>Não</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col">
                                    <label>Se não, informe o motivo</label>
                                    <textarea className="form-control" rows="5" onChange={(evt) => {
                                        this.setState({
                                            paciente: {
                                                ...this.state.paciente,
                                                motivo_nao_acompanhamento: evt.target.value
                                            }
                                        })
                                    }}
                                    value={this.state.paciente.motivo_nao_acompanhamento}></textarea>
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col">
                                    <label>Qual o CRAS de referência?</label>
                                    <textarea required className="form-control" rows="5" onChange={(evt) => {
                                        this.setState({
                                            paciente: {
                                                ...this.state.paciente,
                                                cras_referencia: evt.target.value
                                            }
                                        })
                                    }}
                                    value={this.state.paciente.cras_referencia}></textarea>
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col">
                                    <label>Dados de contato</label>
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col">
                                    <label>Cidade</label>
                                    <input required type="text" className="form-control"  value={this.state.paciente.cidade} onChange={(evt) => {
                                        this.setState({
                                            paciente: {
                                                ...this.state.paciente,
                                                cidade: evt.target.value
                                            }
                                        })
                                    }}/>
                                </div>
                                <div className="col">
                                    <label>Endereço (Logradouro + Número)</label>
                                    <input required type="text" className="form-control"  value={this.state.paciente.endereco} onChange={(evt) => {
                                        this.setState({
                                            paciente: {
                                                ...this.state.paciente,
                                                endereco: evt.target.value
                                            }
                                        })
                                    }}/>
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col">
                                    <label>Telefone</label>
                                    <input required type="phone" className="form-control"  value={this.state.paciente.telefone} onChange={(evt) => {
                                        this.setState({
                                            paciente: {
                                                ...this.state.paciente,
                                                telefone: evt.target.value
                                            }
                                        })
                                    }}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    {
                                        this.state.errors.map(err => (<p key={err} className="text-danger">{err}</p>))
                                    }
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col text-center">
                                    <button className="btn btn-success" type="submit">
                                        Salvar
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
//'cidade', 'endereco', 'telefone', 'cartao_sus', 'ubs_origem', 'escola', 'ano', 'periodo'