import React, { Component } from 'react'
import TitlePage from '../Components/TitlePage'
import { Link } from 'react-router-dom'
import API from '../API'

export default class AlertaPage extends Component{
    state = {
        alertas: []
    }

    constructor(props){
        super(props)
        API.alerta.get().then(data => this.setState({alertas: data}))
    }

    render(){
        return (
            <div className="container-fluid">
                <TitlePage text="Alertas de pacientes com faltas"/>
                <div className="row">
                    <div className="col">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Código do paciente</th>
                                    <th>Nome</th>
                                    <th>Psicólogo</th>
                                    <th>Contato do paciente</th>
                                    <th>Qtde. Faltas</th>
                                    <th>Última consulta</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.alertas.map(alerta => {
                                        return (
                                            <tr key={alerta.id}>
                                                <td>{alerta.paciente.id}</td>
                                                <td><Link to={`/paciente/${alerta.paciente.id}`}>{alerta.paciente.nome}</Link></td>
                                                <td>{alerta.psicologo.nome}</td>
                                                <td>{alerta.paciente.telefone}</td>
                                                <td>{alerta.qtdeFaltas}</td>
                                                <td>
                                                    {
                                                        alerta.ultima_consulta == null ? "Indisponível" :
                                                        alerta.ultima_consulta.dataHora
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}