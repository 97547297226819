import React, { Component } from 'react'
import TitlePage from '../Components/TitlePage'
import API from '../API'
import Modal from 'react-modal'
import swal from 'sweetalert'

export default class FilaPage extends Component{

    constructor(props){
        super(props)

        this.state.psicologo = {}

        const json = localStorage.getItem("token")
        if(json){
            const usuario = JSON.parse(atob(json.split(".")[1]))
            this.state.psicologo = usuario.psicologo
            // this.setState({
            //     psicologo: usuario.psicologo
            // })
        }
        API.fila.get().then(data => this.setState({encaminhamentos: data.data}))
    }
    state = {
        encaminhamentos: [],
        forwardInfo: false,
        encaminhamento: {
            paciente: {}
        },
        date: ""
    }

    ver = (encaminhamento) => {
        this.setState({forwardInfo:true, encaminhamento})
    }

    closeForwardModal = () => {
        this.setState({forwardInfo:false})
    }

    agendar = (encaminhamento) => {
        console.log("Agendar consulta")
        this.setState({markConsult:true, encaminhamento})
    }

    closeConsultModal = (forwardings) => {
        if(forwardings){
            this.setState({
                markConsult: false,
                encaminhamentos: forwardings
            })
        }else{
            this.setState({markConsult:false})
        }
    }

    saveConsult = (evt) => {
        evt.preventDefault()

        const {date} = this.state

        if(date === ""){
            swal("Obrigatório preencher campo data da primeira consulta.")
            return;
        }else{
            const consult = {
                dataHora:date,
                id_encaminhamento: this.state.encaminhamento.id,
                presenca: false,
                id_psicologo: this.state.psicologo.id
            }

            API.consulta.save(consult)
                .then(res => {
                    swal("Consulta agendada com sucesso. Consulte agenda.")
                    const newForwardings = this.state.encaminhamentos.filter(enc => enc.id !== consult.id_encaminhamento)
                    // console.log(newForwardings)
                    
                    this.closeConsultModal(newForwardings)
                }).catch(err => {
                    swal("Falha ao agendar consulta. Confira os dados e tente novamente.")
                })
        }
    }

    render(){
        return (
            <div className="container-fluid">
                <Modal
                    isOpen={this.state.forwardInfo}
                    onRequestClose={this.closeForwardModal}
                    ariaHideApp={false}
                >
                    <TitlePage text="Dados do encaminhamento"/>

                    <p><strong>Nome do Paciente: </strong>{this.state.encaminhamento.paciente.nome}</p>
                    <p><strong>Data do envio:</strong> {this.state.encaminhamento.data_criacao}</p>
                    <p><strong>Queixa - História - Descrição: </strong><br/>{this.state.encaminhamento.descricao}</p>
                    <p><strong>Hipótese Diagnosticada: </strong><br/>{this.state.encaminhamento.hipotese_diagnosticada}</p>
                    <p><strong>Motivo do encaminhamento: </strong><br/>{this.state.encaminhamento.motivo_encaminhamento}</p>
                </Modal>

                <Modal
                    isOpen={this.state.markConsult}
                    onRequestClose={() => this.closeConsultModal(this.state.encaminhamentos)}
                    ariaHideApp={false}
                >
                    <TitlePage text="Agendar consulta"/>

                    <p><strong>Nome do Paciente: </strong>{this.state.encaminhamento.paciente.nome}</p>
                    <p><strong>Data do envio:</strong> {this.state.encaminhamento.data_criacao}</p>
                    <p><strong>Descrição: </strong><br/>{this.state.encaminhamento.descricao}</p>

                    <form className="form" onSubmit={this.saveConsult}>
                        <div className="row">
                            <div className="col form-group">
                                <label>Data da primeira consulta&nbsp;</label>
                                <input type="date" onChange={(field) => this.setState({date: field.target.value})} value={this.state.date} className="form-control"/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col text-center">
                                <button className="btn btn-default" type="submit">Agendar</button>
                            </div>
                        </div>
                    </form>
                </Modal>
                <TitlePage text="Encaminhamentos na fila de espera"/>
                <div className="row">
                    <div className="col">

                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Código do paciente</th>
                                    <th>Nome</th>
                                    <th>Data de encaminhamento</th>
                                    <th>Usuário que encaminhou</th>
                                    <th>Psicólogo responsável</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.encaminhamentos.map(encaminhamento => {
                                        return (
                                            <tr key={encaminhamento.id}>
                                                <td>{encaminhamento.paciente.id}</td>
                                                <td>{encaminhamento.paciente.nome}</td>
                                                <td>{encaminhamento.data_criacao}</td>
                                                <td>{encaminhamento.usuario.nome + " / " + encaminhamento.usuario.funcao}</td>
                                                {
                                                    encaminhamento.consultas !== null ?
                                                        <td>{encaminhamento.consultas[0].psicologo.nome}</td>
                                                    :
                                                        <td className="text-danger">Não agendado ainda</td>
                                                }
                                                <td>
                                                    <button className="btn btn-primary btn-sm" onClick={() => this.ver(encaminhamento)}>
                                                        Ver
                                                    </button>&nbsp;
                                                    <button className="btn btn-warning btn-sm" onClick={() => this.agendar(encaminhamento)}>
                                                        Agendar
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}