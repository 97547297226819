import React, { Component } from 'react'
import TitlePage from '../Components/TitlePage'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import ptbrLocale from '@fullcalendar/core/locales/pt-br'
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import API from '../API'
import Modal from 'react-modal'
import swal from 'sweetalert'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faSquare } from '@fortawesome/free-solid-svg-icons'

export default class AgendaPage extends Component{

    constructor(props){
        super(props)
        
        API.consulta.get().then(data => {
            this.setState({consults: data})
        })
    }
    state = {
        consults: [],
        modalConsultOpened: false,
        consult: {
            paciente:{},
            observacoes: ''
        }
    }

    openModalConsult = (consult_id) => {
        const consult = this.state.consults.filter(consult => parseInt(consult.id) === parseInt(consult_id))[0]
        
        this.setState({
            consult, modalConsultOpened : true
        })
    }

    closeConsultModal = () => {
        this.setState({
            modalConsultOpened: false
        })
    }

    markPresent = () => {
        const {consult} = this.state
        consult.presenca = true

        API.consulta.update(consult).then(res => {
            swal("Dados salvos com sucesso.")
        }).catch(err => {
            console.log(err)
            swal("Falha ao salvar.")
        })
    }

    markFault = () => {
        const {consult} = this.state
        consult.presenca = false

        API.consulta.update(consult).then(res => {
            swal("Dados salvos com sucesso.")
        }).catch(err => {
            console.log(err)
            swal("Falha ao salvar.")
        })
    }

    render(){
        console.log(this.state.consults)
        const consults = this.state.consults.map(consult => {
            let color = 'blue'
            let textColor = 'white'
            if(+consult.presenca === 1){
                color = 'green'
            }else if(consult.presenca !== null && +consult.presenca === 0){
                if(consult.justificativa === null || consult.justificativa === ""){
                    color = 'red'
                }else{
                    color = 'yellow'
                    textColor = 'black'
                }
            }
            return {
                title: consult.paciente.nome,
                date: consult.dataHora,
                id: consult.id,
                color,
                textColor
            }
        })
        return (
            <div className="container-fluid">
                <Modal
                    isOpen={this.state.modalConsultOpened}
                    onRequestClose={this.closeConsultModal}
                    ariaHideApp={false}
                >
                    <TitlePage text="Dados da consulta"/>

                    <div className="row">
                        <div className="col form-group">
                            <p><strong>Nome do Paciente: </strong>{this.state.consult.paciente.nome}</p>
                            <p><strong>Data do envio:</strong> {this.state.consult.dataHora}</p>
                            <p><strong>Observações: </strong><br/></p>
                            <textarea className="form-control" onChange={(event) => {
                                this.setState({consult: {
                                    ...this.state.consult,
                                    observacoes: event.target.value
                                }})
                            }} value={this.state.consult.observacoes ? this.state.consult.observacoes : ''}></textarea>
                            <p><strong>Justificativa para falta: </strong><br/></p>
                            <textarea className="form-control" onChange={(event) => {
                                this.setState({consult: {
                                    ...this.state.consult,
                                    justificativa: event.target.value
                                }})
                            }} value={this.state.consult.justificativa ? this.state.consult.justificativa : ''}></textarea>
                        </div>
                    </div>

                    {
                        this.state.consult.presenca == null ?

                        <div className="row form-group">
                            <div className="col text-center">
                                <button className="btn btn-success" onClick={this.markPresent}>Marcar presença</button>&nbsp;
                                <button className="btn btn-danger" onClick={this.markFault}>Marcar Falta</button>
                            </div>
                        </div> :
                        null
                    }
                </Modal>
                <TitlePage text="Agenda do psicólogo"/>
                <div className="row">
                    <div className="col text-left" style={{
                        margin: 20
                    }}>
                        <FontAwesomeIcon icon={faSquare} style={{
                            color: 'blue',
                            marginLeft: 30
                        }} /> Não consultou ainda

                        <FontAwesomeIcon icon={faSquare} style={{
                            color: 'red',
                            marginLeft: 30
                        }} /> Faltou à consulta

                        <FontAwesomeIcon icon={faSquare} style={{
                            color: 'yellow',
                            marginLeft: 30
                        }} /> Faltou à consulta com justificativa

                        <FontAwesomeIcon icon={faSquare} style={{
                            color: 'green',
                            marginLeft: 30
                        }} /> Compareceu à consulta

                    </div>
                </div>
                <div className="row">
                    <div className="col">

                    {!this.state.modalConsultOpened ? (
                        <FullCalendar defaultView="dayGridMonth" plugins={[ dayGridPlugin ]} events={
                            consults
                        }
                        locale={ptbrLocale} 
                        eventClick={(info) => {
                            this.openModalConsult(info.event.id)
                        }}
                        />
                    ) : null}
                    </div>
                </div>
            </div>
        )
    }
}