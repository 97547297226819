import React, { Component } from 'react'
import TitlePage from '../Components/TitlePage'
import API from '../API'

export default class ConsultaPage extends Component{

    constructor(props){
        super(props)

        this.state.psicologo = {}

        const json = localStorage.getItem("token")
        if(json){
            const usuario = JSON.parse(atob(json.split(".")[1]))
            this.state.psicologo = usuario.psicologo
        }

        API.consulta.getById(this.props.consulta).then(consulta => {
            this.setState({consulta, load: true})
        }).catch(err => {
            this.setState({
                load: false,
                msg_error: "Falha ao carregar dados da consulta."
            })
        })
    }
    state = {
        consulta: {
            paciente: {}
        }
    }

    render(){
        if(this.state.load){
            return (
                <div className="container-fluid">
                    <TitlePage text="Dados da Consulta"/>
                    <div className="row">
                        <div className="col">
                            <strong>Nome do Paciente: </strong>{this.state.consulta.paciente.nome}
                        </div>
                        <div className="col">
                            <strong>Psicólogo: </strong>{this.state.consulta.psicologo.nome}
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col">
                            <strong>Data Hora: </strong>{this.state.consulta.dataHora}
                        </div>
                        <div className="col">
                            <strong>Status: </strong>{this.state.consulta.presenca ? "Paciente presente" : "Paciente ausente"}
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col">
                            <strong>Observações: </strong><br/>{this.state.consulta.observacoes}
                        </div>
                    </div>
                </div>
            )
        }else{
            return (
                <div className="container-fluid">
                    <TitlePage text="Dados da Consulta"/>
                    <div className="row">
                        <div className="col">
                            <h4>{this.state.msg_error}</h4>
                        </div>
                    </div>
                </div>
            )
        }
    }
}