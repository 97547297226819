import React, { Component } from 'react'

export default class Footer extends Component{

    
    render(){
        return (
            <div className="row header-top text-center">
              <div className="col-12">
                2019 &copy; Todos os direitos reservados.
              </div>
            </div>
        )
    }
}