import React, { Component } from 'react'
import logo from '../assets/img/logo_ver_fundo_escuro.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileContract, faUser, faList, faCalendar, faFileAlt, faUserSlash, faExclamation, faArchive, faTable} from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import API from '../API'

import '../App.css'

export default class SideBar extends Component{

    constructor(props){
        super(props)

        const token = localStorage.getItem("token")

        if(token){
            const user = JSON.parse(atob(token.split(".")[1]))

            if(user.psicologo){
                user.profile = "psicologo"
            }else{
                user.profile = "user"
            }

            this.state = {
                user
            }
        }else{
            this.state = {
                user: {}
            }
        }

        this.state.alertas = []

        API.alerta.get().then(data => this.setState({alertas: data}))
    }

    menus = [
        {
            icon: faExclamation,
            text: "Alertas de falta",
            url: "/alerta",
            profile: ""
        },
        {
            icon: faFileContract,
            text: "Encaminhamentos",
            url: "/encaminhamentos",
            profile: ""
        },
        {
            icon: faUser,
            text: "Pacientes",
            url: "/pacientes",
            profile: ""
        },
        {
            icon: faUserSlash,
            text: "Pacientes excluídos",
            url: "/pacientes/excluidos",
            profile: ""
        },
        {
            icon: faList,
            text: "Fila",
            url: "/fila",
            profile: "psicologo"
        },
        {
            icon: faCalendar,
            text: "Minha Agenda",
            url: "/agenda",
            profile: "psicologo"
        },
        {
            icon: faTable,
            text: "Agenda dos psicólogos",
            url: "/agendaPsicologo",
            profile: "psicologo"
        },
        {
            icon: faFileAlt,
            text: "Consultas",
            url: "/consulta",
            profile: ""
        },
        {
            icon: faUser,
            text: "Psicólogos",
            url: "/psicologo",
            profile: ""
        },
        {
            icon: faArchive,
            text: "Estatísticas",
            url: "/estatistica",
            profile: ""
        }
    ]
    render(){

        const menu = this.menus.filter(item => {
            return item.profile === "" || item.profile === this.state.user.profile
        })

        return (
            <section className="App-sidebar">
                <div className="row">
                    <div className="col">
                        <img src={logo} className="App-logo" alt="logo" />
                    </div>
                </div>
                <div className="row bg-purple">
                    <div className="col">
                        <h4 className="title">Painel de Controle</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col">

                        {
                            menu.map(item => {
                                return (
                                    <div className="item-sidebar" key={item.url}>
                                        <Link to={item.url}>
                                            <FontAwesomeIcon icon={item.icon} /> 
                                            <span className="text-item">{item.text}</span>
                                            {
                                                item.url === "/alerta" ? 
                                                <span>&nbsp;<span className="qtdeAlertas">{this.state.alertas.length}</span></span> : null
                                            }
                                            
                                        </Link>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
        )
    }
}