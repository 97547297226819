import React, { Component } from 'react'
import TitlePage from '../Components/TitlePage'
import API from '../API'
import swal from 'sweetalert'

export default class PacientesExcluidosPage extends Component{
    state = {
        pacientes: []
    }

    constructor(props){
        super(props)
        API.paciente.getExcluidos().then(data => this.setState({pacientes: data}))
    }

    restore = id => {
        API.paciente.restore(id).then(data => {
            const pacientes = this.state.pacientes.filter(paciente => paciente.id !== id)
            this.setState({
                pacientes
            })

            swal("Restaurado com sucesso.")
        }).catch(err => {
            console.log(err)
            swal("Falha ao restaurar registro.")
        })
    }

    render(){
        return (
            <div className="container-fluid">
                <TitlePage text="Gerenciar pacientes excluídos"/>
                <div className="row">
                    <div className="col">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Código</th>
                                    <th>Nome</th>
                                    <th>Mãe</th>
                                    <th>Data Nasc.</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.pacientes.map(paciente => {
                                        return (
                                            <tr key={paciente.id}>
                                                <td>{paciente.id}</td>
                                                <td>{paciente.nome}</td>
                                                <td>{paciente.mae}</td>
                                                <td>{paciente.data_nascimento}</td>
                                                <td>
                                                    <button className="btn btn-warning btn-sm" onClick={() => {
                                                        this.restore(paciente.id)
                                                    }}>
                                                        Restaurar
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}