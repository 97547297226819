import React, { Component } from 'react'

export default class TitlePage extends Component{
    render(){
        return (
            <div className="row">
                <div className="col">
                    <h2 className="title-page">{this.props.text}</h2>
                </div>
            </div>
        )
    }
}