import React, { Component } from 'react'
import TitlePage from '../Components/TitlePage'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import ptbrLocale from '@fullcalendar/core/locales/pt-br'
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import API from '../API'
import Modal from 'react-modal'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faSquare } from '@fortawesome/free-solid-svg-icons'

export default class AgendaPsicologoPage extends Component{

    constructor(props){
        super(props)

        API.psicologo.get().then(data => {
            this.setState({psicologos: data.data})
        })
    }
    state = {
        consults: [],
        modalConsultOpened: false,
        consult: {
            paciente:{},
            observacoes: ''
        },
        psicologo: null,
        psicologos: []
    }

    openModalConsult = (consult_id) => {
        const consult = this.state.consults.filter(consult => parseInt(consult.id) === parseInt(consult_id))[0]
        
        this.setState({
            consult, modalConsultOpened : true
        })
    }

    closeConsultModal = () => {
        this.setState({
            modalConsultOpened: false
        })
    }

    render(){
        console.log(this.state.consults)
        const consults = this.state.consults.map(consult => {
            let color = 'blue'
            let textColor = 'white'
            if(+consult.presenca === 1){
                color = 'green'
            }else if(consult.presenca !== null && +consult.presenca === 0){
                if(consult.justificativa === null || consult.justificativa === ""){
                    color = 'red'
                }else{
                    color = 'yellow'
                    textColor = 'black'
                }
            }
            return {
                title: consult.paciente.nome,
                date: consult.dataHora,
                id: consult.id,
                color
            }
        })
        if(this.state.psicologo === null){
            return <div className="container-fluid">
                <TitlePage text="Agenda do psicólogo"/>
                <div className="row">
                    <div className="col form-group">
                        <label>Selecione o psicólogo</label>
                        <select className="form-control" onChange={(e) => {
                            const psicologo = this.state.psicologos.filter(p => +p.id === +e.target.value)[0]
                            API.consulta.getByPsicologo(psicologo.id).then(data => {
                                this.setState({consults: data})
                            })
                            this.setState({psicologo})
                        }}>
                            <option key="0">Selecione...</option>
                            {
                                this.state.psicologos.map(psicologo => (
                                    <option key={psicologo.id} value={psicologo.id}>{psicologo.nome}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
            </div>
        }
        return (
            <div className="container-fluid">
                <Modal
                    isOpen={this.state.modalConsultOpened}
                    onRequestClose={this.closeConsultModal}
                    ariaHideApp={false}
                >
                    <TitlePage text="Dados da consulta"/>

                    <div className="row">
                        <div className="col form-group">
                            <p><strong>Nome do Paciente: </strong>{this.state.consult.paciente.nome}</p>
                            <p><strong>Data do envio:</strong> {this.state.consult.dataHora}</p>
                            
                        </div>
                    </div>
                </Modal>
                <TitlePage text="Agenda do psicólogo"/>
                <div className="row">
                    <div className="col text-left" style={{
                        margin: 20
                    }}>
                        <FontAwesomeIcon icon={faSquare} style={{
                            color: 'blue',
                            marginLeft: 30
                        }} /> Não consultou ainda

                        <FontAwesomeIcon icon={faSquare} style={{
                            color: 'red',
                            marginLeft: 30
                        }} /> Faltou à consulta

                        <FontAwesomeIcon icon={faSquare} style={{
                            color: 'yellow',
                            marginLeft: 30
                        }} /> Faltou à consulta com justificativa

                        <FontAwesomeIcon icon={faSquare} style={{
                            color: 'green',
                            marginLeft: 30
                        }} /> Compareceu à consulta

                    </div>
                </div>
                <div className="row">
                    <div className="col">

                    {!this.state.modalConsultOpened ? (
                        <FullCalendar defaultView="dayGridMonth" plugins={[ dayGridPlugin ]} events={
                            consults
                        }
                        locale={ptbrLocale} 
                        eventClick={(info) => {
                            this.openModalConsult(info.event.id)
                        }}
                        />
                    ) : null}
                    </div>
                </div>
            </div>
        )
    }
}