import React, { Component } from 'react'
import { useParams } from 'react-router-dom'
import API from '../API'
import swal from 'sweetalert'
import ReactToPrint from 'react-to-print'
// import { ImprimirEncaminhamentoClass } from './ImprimirEncaminhamentoPage'

export class ImprimirEncaminhamentoClass extends Component{
    constructor(props){
        super(props)

        const id = props.encaminhamento_id

        this.state = {
            encaminhamento: {
                paciente: {}
            }
        }

        API.encaminhamento.get(id).then(encaminhamento => {
            this.setState({encaminhamento})
        }).catch(err => {
            console.log(err)
            swal("Falha ao carregar dados.")
        })
    }
    render(){

        console.log(this.state)

        const {encaminhamento} = this.state
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col text-center print-title">
                        FICHA DE ENCAMINHAMENTO PARA PSICOTERAPIA
                    </div>
                </div>
    
                <div className="row">
                    <div className="col print-title">
                        IDENTIFICAÇÃO DO PACIENTE
                    </div>
                </div>
    
                <div className="row">
                    <div className="col">
                        <p>Nome: <span className="text-underscore">{encaminhamento.paciente.nome}</span></p>
                    </div>
                </div>
    
                <div className="row">
                    <div className="col">
                        <p>Data de Nascimento: <span className="text-underscore">{encaminhamento.paciente.data_nascimento}</span> </p>
                    </div>
                    <div className="col">
                        <p>Município: <span className="text-underscore">{encaminhamento.paciente.cidade}</span></p>
                    </div>
                </div>
    
                <div className="row">
                    <div className="col">
                        <p>Endereço: <span className="text-underscore">{encaminhamento.paciente.endereco}</span></p>
                    </div>
                </div>
    
                <div className="row">
                    <div className="col">
                        <p>Telefone: <span className="text-underscore">{encaminhamento.paciente.telefone}</span></p>
                    </div>
                </div>
    
                <div className="row">
                    <div className="col">
                        <p>Número do Carão do SUS: <span className="text-underscore">{encaminhamento.paciente.cartao_sus}</span></p>
                    </div>
                    <div className="col">
                        <p>UBS de origem: <span className="text-underscore">{encaminhamento.paciente.ubs_origem}</span></p>
                    </div>
                </div>
    
                <div className="row">
                    <div className="col">
                        <p>Escola: <span className="text-underscore">{encaminhamento.paciente.escola}</span></p>
                    </div>
                    <div className="col">
                        <p>Ano: <span className="text-underscore">{encaminhamento.paciente.ano}</span></p>
                    </div>
                    <div className="col">
                        <p>Período: <span className="text-underscore">{encaminhamento.paciente.periodo}</span></p>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <p>É acompanhado pelo CRAS? <span className="text-underscore">{encaminhamento.paciente.acompanhamento_cras === "0" ? "Não" : "Sim"}</span></p>
                    </div>
                </div>

                {
                    encaminhamento.paciente.acompanhamento_cras === "0" ? 
                    <div className="row">
                        <div className="col">
                            <p>Motivo do não acompanhamento: <span className="text-underscore">{encaminhamento.paciente.motivo_nao_acompanhamento}</span></p>
                        </div>
                    </div>
                    :
                    null
                }

                <div className="row">
                    <div className="col">
                        <p>CRAS de referência: <span className="text-underscore">{encaminhamento.paciente.cras_referencia}</span></p>
                    </div>
                </div>
    
                <hr/>
    
                <div className="row">
                    <div className="col print-title">
                        RESUMO DO ATENDIMENTO
                    </div>
                </div>
    
                <div className="row">
                    <div className="col">
                        <p className="text-underscore">Queixa, história, exame clínico: </p>
                        <p>{encaminhamento.descricao}</p>
                    </div>
                </div>
    
                <div className="row">
                    <div className="col">
                        <p className="text-underscore">Motivo do encaminhamento: </p>
                        <p>{encaminhamento.motivo_encaminhamento}</p>
                    </div>
                </div>
    
                <div className="row">
                    <div className="col">
                        <p className="text-underscore">Hipótese diagnosticada: </p>
                        <p>{encaminhamento.hipotese_diagnosticada}</p>
                    </div>
                </div>
    
                <hr/>
    
                <div className="row margin-top">
                    <div className="col text-center">
                        <p>Data: __/__/____</p>
                    </div>
    
                    <div className="col text-center">
                        <p>_____________________________</p>
                        <p>Assinatura e carimbo</p>
                    </div>
                </div>
                
                <div className="row margin-top">
                    <div className="col text-center">
                    <ReactToPrint
                        trigger={() => <div>
                            <button className="btn-print">Imprimir</button>
                            <a className="btn-print" href="/encaminhamentos">Voltar</a>
                        </div>}
                        content={() => this}
                    />
                    </div>
                </div>
            </div>
        )
    }
}

const ImprimirEncaminhamentoPage = () => {
    const {id} = useParams()

    return <ImprimirEncaminhamentoClass encaminhamento_id={id} />
    
}

export default ImprimirEncaminhamentoPage