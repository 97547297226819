import React, { Component } from 'react'

export default class Header extends Component{

    constructor(props){
        super(props)

        const token = localStorage.getItem("token")

        if(token){
            this.state = {
                usuario: JSON.parse(atob(token.split(".")[1]))
            }
        }else{
            this.state = {
                usuario: {}
            }
        }
    }

    logoff = () => {
        localStorage.removeItem("token")
        window.location = "/"
    }

    render(){
        return (
            <header className="row">
                <div className="col">
                <p>Olá, {this.state.usuario.nome}!</p>
                </div>
                <div className="col text-right">
                    <p>
                        <a href="/" onClick={this.logoff}>Sair</a>
                    </p>
                </div>
            </header>
        )
    }
}