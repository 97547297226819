import React, { Component } from 'react'
import TitlePage from '../Components/TitlePage'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import API from '../API'
import swal from 'sweetalert'

export default class PacientesPage extends Component{
    state = {
        pacientes: []
    }

    constructor(props){
        super(props)
        API.paciente.get().then(data => this.setState({pacientes: data.data}))
    }

    delete = id => {
        API.paciente.delete(id).then(data => {
            const pacientes = this.state.pacientes.filter(paciente => paciente.id !== id)
            this.setState({
                pacientes
            })

            swal("Excluído com sucesso.")
        }).catch(err => {
            console.log(err)
            swal("Falha ao excluir registro.")
        })
    }

    render(){
        return (
            <div className="container-fluid">
                <TitlePage text="Gerenciar pacientes cadastradas"/>
                <div className="row">
                    <div className="col">
                        <button className="btn btn-default">
                            <Link to="pacientes/novo">Cadastrar novo</Link>
                        </button>

                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Código</th>
                                    <th>Nome</th>
                                    <th>Mãe</th>
                                    <th>Data Nasc.</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.pacientes.map(paciente => {
                                        return (
                                            <tr key={paciente.id}>
                                                <td>{paciente.id}</td>
                                                <td>{paciente.nome}</td>
                                                <td>{paciente.mae}</td>
                                                <td>{paciente.data_nascimento}</td>
                                                <td>
                                                    <button className="btn btn-danger btn-sm" onClick={() => {
                                                        this.delete(paciente.id)
                                                    }}>
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </button>&nbsp;
                                                    <button className="btn btn-warning btn-sm" onClick={() => {
                                                        window.location = `/historico/${paciente.id}`
                                                    }}>
                                                        Histórico
                                                    </button>&nbsp;
                                                    <button className="btn btn-success btn-sm" onClick={() => {
                                                        window.location = `/paciente/${paciente.id}/novaConsulta`
                                                    }}>
                                                        Nova Consulta
                                                    </button>&nbsp;
                                                    {
                                                        +paciente.acompanhamento_retirado === 1 ? null : 
                                                        <button className="btn btn-primary btn-sm" onClick={() => {
                                                            API.removerAcompanhamento(paciente.id)
                                                                .then(() => {
                                                                    swal("Acompanhamento removido com sucesso.")
                                                                })
                                                                .catch(err => {swal("Falha no servidor ao remover acompanhamento.")})
                                                        }}>
                                                            Remover Acompanhamento
                                                        </button>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}